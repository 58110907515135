export default class LocalStorageUtil {
    static set(key, val) {
        window.localStorage.setItem(key, JSON.stringify(val));
    }
    static get(key, def) {
        const valStr = window.localStorage.getItem(key);
        if (valStr)
            return JSON.parse(valStr);
        return def;
    }
    static del(key) {
        window.localStorage.removeItem(key);
    }
}
