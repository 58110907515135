let languageInfo = {};
const aliasMap = {
    'openjdk-11': 'java',
    deno: 'typescript',
    mono: 'csharp',
    'dotnet-script': 'csharp',
    'kotlin-script': 'kotlin',
};
async function init() {
    const res = await window.fetch(process.env.languagesEndpoint);
    languageInfo = (await res.json());
}
export function languageColor(lang) {
    var _a, _b, _c;
    lang = lang.toLowerCase();
    lang = (_a = aliasMap[lang]) !== null && _a !== void 0 ? _a : lang;
    return (_c = (_b = languageInfo[lang]) === null || _b === void 0 ? void 0 : _b.color) !== null && _c !== void 0 ? _c : '#ffffff';
}
export default {
    init,
};
