import { writable } from 'svelte/store';
export const snackbarStore = writable({
    show: false,
});
let timer;
export function showSnackbar(message, type, duration = 5000) {
    snackbarStore.set({ show: true, message, type });
    if (timer)
        clearTimeout(timer);
    timer = setTimeout(() => snackbarStore.set({ show: false, message, type }), duration);
}
